import React, { Component, createContext } from "react";
import moment from "moment";
import { API_URL } from "../Utils/config";

export const FilterContext = createContext();

class FilterContextProvider extends Component {
  state = {
    FromDate: localStorage.getItem("fromDate"),
    ToDate: localStorage.getItem("toDate"),
    Company: localStorage.getItem("Company"),
    PlantState: localStorage.getItem("PlantState"),
    WorkCentre: localStorage.getItem("WorkCentre"),

    Enq_PlantCategory: [],
    Enq_PlantType: [],
    Enq_HireCategory: [],
  };

  componentDidMount = () => {
    const PlantType = `${API_URL}enquiry/getplantstypes`;
    const PlantCatURL = `${API_URL}enquiry/getplantcategories`;
    const HireCatURL = `${API_URL}enquiry/gethirecategories`;

    const promises = Promise.all([
      fetch(PlantType),
      fetch(PlantCatURL),
      fetch(HireCatURL),
    ]);

    promises
      .then(([res1, res2, res3]) => {
        return Promise.all([res1.json(), res2.json(), res3.json()]);
      })
      .then(([res1, res2, res3]) => {
        this.setState({ Enq_PlantType: res1.lookupInfo });
        this.setState({ Enq_PlantCategory: res2.PlantCategories });
        this.setState({ Enq_HireCategory: res3.lookupInfo });
      });
  };

  UpdateCompany = () => {
    this.setState({ Company: localStorage.getItem("Company") });
  };
  // Thilini
  UpdateWorkCentre = () => {
    this.setState({ WorkCentre: localStorage.getItem("WorkCentre") });
  };

  UpdateDates = () => {
    this.setState({ FromDate: localStorage.getItem("fromDate") });
    this.setState({ ToDate: localStorage.getItem("toDate") });
  };

  UpdateState = () => {
    this.setState({ PlantState: localStorage.getItem("PlantState") });
  };

  render() {
    //Loading the default values to the Global Filters
    localStorage.setItem("PU_hoursPerMonth", 172);

    if (localStorage.getItem("Company") == null) {
      localStorage.setItem("Company", "EPH");
      this.setState({ Company: "EPH" });
    }
    // Thilini

    if (localStorage.getItem("WorkCentre") == null) {
      localStorage.setItem("WorkCentre", "");
      this.setState({ WorkCentre: "" });
    }

    if (localStorage.getItem("PlantState") == null) {
      localStorage.setItem("PlantState", "ALL");
      this.setState({ PlantState: "ALL" });
    }

    let nextFrom = moment().utc().startOf("year");
    let nextTo = moment().utc().endOf("year");

    if (this.state.FromDate == null) {
      localStorage.setItem(
        "fromDate",
        // moment(nextFrom).utc().startOf("day").toISOString().split(".")[0]
        moment(nextFrom).startOf('day').format('YYYY-MM-DDTHH:mm:ss')
      );
      this.setState({ FromDate: localStorage.getItem("fromDate") });
    }
    if (this.state.ToDate == null) {
      localStorage.setItem(
        "toDate",
        // moment(nextTo).utc().endOf("day").toISOString().split(".")[0]
        moment(nextTo).endOf('day').format('YYYY-MM-DDT23:59:59')
      );
      this.setState({ ToDate: localStorage.getItem("toDate") });
    }

    return (
      <FilterContext.Provider
        value={{
          ...this.state,
          UpdateCompany: this.UpdateCompany,
          UpdateDates: this.UpdateDates,
          UpdateState: this.UpdateState,
          UpdateWorkCentre: this.UpdateWorkCentre,
        }}
      >
        {this.props.children}
      </FilterContext.Provider>
    );
  }
}

export default FilterContextProvider;
